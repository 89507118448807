const Platform = {
  PSN: "psn",
  Steam: "steam",
  XboxLive: "xbl",
  Battle: "battle",
  ActivisionId: "uno",
  ActivisionTag: "acti",
};

export { Platform };
